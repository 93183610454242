import corroEnrollatdorJMB2024 from 'assets/icons/corro-enrotllador-JMB-2024.png'
import corroEnrotllador from 'assets/icons/corro-enrotllador.png'
import pinyonsPlegador from 'assets/icons/pinyons-plegador.png'
import relacioPlegadorJMB2024 from 'assets/icons/relacio-plegador-JMB-2024.png'
import tensioPlegadorJMB2024 from 'assets/icons/tensio-plegador-JMB-2024.png'
import tensioPlegador from 'assets/icons/tensio-plegador.png'
import PropTypes from 'prop-types'

export default function FolderForm({ machineCode }) {
  const commonInputStyles = 'p-0.5 text-sm border border-gray-300 rounded-sm'

  return (
    <div className="grid h-full grid-cols-3 divide-x divide-blue-200 divide-dashed">
      {/* Campos si 'machineCode' es 'PAL': A. Calandra */}
      {/* Campos si 'machineCode' es 'MCH': A. Calandra, Posició */}
      {/* Campos si 'machineCode' es 'SNT': A. Calandra, Pinyons Plegador */}
      {/* Campos si 'machineCode' es 'MYR': A. Calandra, Pinyons Plegador, Tensió */}
      {/* Campos si 'machineCode' es 'TRT': A. Calandra, Posició, Torque */}
      {/* Campos si 'machineCode' es 'JMB': A. Calandra, ?, Pinyons Plegador, Tensió Plegador */}
      {/* Campos si 'machineCode' es 'PLT': A. Calandra, Delta, Pinyons Plegador, Tensió del Plegador, Corró enrotllador */}

      {/* Columna 1: */}
      <div className="flex flex-col col-span-1 p-2 space-y-3 print:space-y-1">
        {/* Campo 'A. Calandra': */}
        {/* Se muestra en todos los tipos de 'machineCode', en 'JMB', 'PLT', 'PAL', 'TRT', 'MCH', 'MYR' y 'SNT': */}
        <div className="flex flex-col items-start justify-start w-full">
          <div className="col-span-12 text-sm font-ms-semi">A. Calandra:</div>

          <input
            type="text"
            className={`${commonInputStyles} w-full`}
            style={{ backgroundColor: 'white', color: 'black' }}
            disabled
          />
        </div>

        {/* ------------------------------ */}

        {/* Campo 'Delta': */}
        {/* Se muestra si 'machineCode' es 'PLT': */}
        {machineCode === 'PLT' && (
          <div className="flex flex-col items-start justify-start w-full">
            <div className="col-span-12 text-sm font-ms-semi">Delta:</div>

            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>
        )}

        {/* ------------------------------ */}

        {/* Campos 'Piston JUM y Pinyons Plegador (3, 4)': */}
        {/* Se muestra si 'machineCode' es 'JMB': */}
        {machineCode === 'JMB' && (
          <div className="grid w-full grid-cols-4">
            <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
              Relació Plegador:
            </label>

            <div className="relative w-56 col-span-4 print:w-52">
              <img
                src={relacioPlegadorJMB2024}
                alt="Relació Plegador"
                className="w-full"
              />

              <div className="absolute bottom-0 left-0 flex items-center justify-around w-full h-full">
                <input
                  type="text"
                  className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />

                <input
                  type="text"
                  className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>
            </div>
          </div>
        )}

        {/* Campo 'Pinyons Plegador (1, 2)': */}
        {/* Se muestra si 'machineCode' es 'MYR', 'SNT', 'PLT', 'JMB': */}
        {machineCode !== 'TRT' &&
          machineCode !== 'PAL' &&
          machineCode !== 'MCH' &&
          machineCode !== 'JMB' && (
            <div className="grid w-full grid-cols-4">
              <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
                Pinyons Plegador (1, 2):
              </label>

              <div className="relative w-40 col-span-4 print:w-36">
                <img
                  src={pinyonsPlegador}
                  alt="pinyons plegador"
                  className="w-full"
                />

                <div className="absolute bottom-0 right-2 h-full flex flex-col items-end justify-around w-full pr-1 py-1 print:pr-0.5 print:py-0.5">
                  <input
                    type="text"
                    className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                    style={{ backgroundColor: 'white', color: 'black' }}
                    disabled
                  />

                  <input
                    type="text"
                    className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                    style={{ backgroundColor: 'white', color: 'black' }}
                    disabled
                  />
                </div>
              </div>
            </div>
          )}
      </div>

      {/* Columna 2: */}
      <div className="flex flex-col col-span-1 p-2 space-y-3">
        {/* Campo 'Tensió Plegador (1, 2)': */}
        {/* Se muestra si 'machineCode' es 'PLT': */}
        {machineCode === 'PLT' && (
          <div className="flex flex-col w-full">
            <label className="flex items-center w-full pb-3 text-sm text-grey-darker font-ms-semi">
              Tensió Plegador (1, 2):
            </label>

            <div className="grid w-full grid-cols-2 gap-x-2">
              <div className="flex flex-col justify-center col-span-1 print:space-y-1">
                <div className="flex flex-row w-full space-x-1">
                  <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                    P1:
                  </label>

                  <input
                    type="text"
                    className={`${commonInputStyles} w-9/12`}
                    style={{ backgroundColor: 'white', color: 'black' }}
                    disabled
                  />
                </div>

                <div className="flex flex-row w-full space-x-1">
                  <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                    P2:
                  </label>

                  <input
                    type="text"
                    className={`${commonInputStyles} w-9/12`}
                    style={{ backgroundColor: 'white', color: 'black' }}
                    disabled
                  />
                </div>

                <div className="flex flex-row w-full space-x-1">
                  <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                    P3:
                  </label>

                  <input
                    type="text"
                    className={`${commonInputStyles} w-9/12`}
                    style={{ backgroundColor: 'white', color: 'black' }}
                    disabled
                  />
                </div>
              </div>

              <div className="relative w-full col-span-1">
                <img
                  src={tensioPlegador}
                  alt="Tensió Plegador"
                  className="mx-auto w-28 print:w-24"
                />

                <div className="absolute bottom-0 left-0 flex flex-col items-center justify-between w-full h-full">
                  <input
                    type="text"
                    className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                    style={{ backgroundColor: 'white', color: 'black' }}
                    disabled
                  />

                  <input
                    type="text"
                    className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                    style={{ backgroundColor: 'white', color: 'black' }}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Tensió Plegador (3, 4) */}
        {/* Se muestra si 'machineCode' es 'JMB': */}
        {machineCode === 'JMB' && (
          <div className="grid w-full grid-cols-4">
            <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
              Tensió Plegador:
            </label>

            <div className="relative w-56 col-span-4 print:w-52">
              <img
                src={tensioPlegadorJMB2024}
                alt="Tensió Plegador"
                className="w-full"
              />

              <div className="absolute bottom-0 left-0 flex items-center justify-around w-full h-full">
                <input
                  type="text"
                  className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />

                <input
                  type="text"
                  className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>
            </div>
          </div>
        )}

        {/* Campo 'Posició': */}
        {/* Se muestra si 'machineCode' es 'MCH': */}
        {machineCode === 'MCH' && (
          <div className="flex flex-col items-start justify-start w-full">
            <div className="col-span-12 text-sm font-ms-semi">Posició:</div>

            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>
        )}

        {/* Campos 'Posició y Torque': */}
        {/* Se muestra si 'machineCode' es 'TRT': */}
        {machineCode === 'TRT' && (
          <>
            <div className="flex flex-col items-start justify-start w-full">
              <div className="col-span-12 text-sm font-ms-semi">Posició:</div>

              <input
                type="text"
                className={`${commonInputStyles} w-full`}
                style={{ backgroundColor: 'white', color: 'black' }}
                disabled
              />
            </div>

            <div className="flex flex-col items-start justify-start w-full">
              <div className="col-span-12 text-sm font-ms-semi">Torque:</div>

              <input
                type="text"
                className={`${commonInputStyles} w-full`}
                style={{ backgroundColor: 'white', color: 'black' }}
                disabled
              />
            </div>
          </>
        )}

        {/* Campo 'Tensió': */}
        {/* Se muestra si 'machineCode' es 'MYR': */}
        {machineCode === 'MYR' && (
          <div className="flex flex-col items-start justify-start w-full">
            <div className="col-span-12 text-sm font-ms-semi">Tensió:</div>

            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>
        )}
      </div>

      {/* Columna 3: */}
      <div className="flex flex-col col-span-1 p-2 space-y-3">
        {/* Campo 'Corró enrotllador': */}
        {/* Se muestra si 'machineCode' es 'PLT': */}
        {machineCode === 'PLT' && (
          <div className="grid w-full grid-cols-4">
            <label className="flex items-center col-span-4 pb-1 text-sm text-grey-darker font-ms-semi">
              Corró enrotllador:
            </label>

            <div className="relative w-full col-span-4">
              <img
                src={corroEnrotllador}
                alt="Tensió Plegador"
                className="mx-auto md:w-28 xl:w-32 print:w-28"
              />

              <div className="absolute bottom-0 flex flex-col items-start justify-around w-full h-full md:left-0 2xl:left-10">
                <input
                  type="text"
                  className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />

                <input
                  type="text"
                  className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>

              <div className="absolute bottom-0 flex flex-col items-end justify-between w-full h-full md:right-0 2xl:right-10">
                <input
                  type="text"
                  className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />

                <input
                  type="text"
                  className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>
            </div>
          </div>
        )}

        {/* Tensió Plegador (3, 4) */}
        {/* Se muestra si 'machineCode' es 'JMB': */}
        {machineCode === 'JMB' && (
          <div className="flex flex-col w-full col-span-12">
            <label className="flex items-center w-full pb-1 text-sm text-grey-darker font-ms-semi">
              Corró Enrotllador:
            </label>

            <div className="flex flex-row space-x-4">
              <div className="flex items-start justify-start pt-6">
                <input
                  type="text"
                  className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>

              <img
                src={corroEnrollatdorJMB2024}
                alt="Corró Enrotllador"
                className="w-16 print:w-14"
              />

              <div className="flex items-end justify-end pb-6">
                <input
                  type="text"
                  className={`${commonInputStyles} w-12 xl:w-14 print:w-16`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

FolderForm.propTypes = {
  machineCode: PropTypes.string.isRequired
}
