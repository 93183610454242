import logoGRS from 'assets/img/logo-GRS.png'
import Block from 'components/FTTeixiduria/Details/Block/Block'
import CloneFTModal from 'components/FTTeixiduria/Details/CloneFTModal'
import DeleteModal from 'components/FTTeixiduria/Details/DeleteModal'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import { ErrorMessage, Form, Formik } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { AiOutlineHistory, AiOutlinePrinter } from 'react-icons/ai'
import { CgSpinner } from 'react-icons/cg'
import { FaTrash } from 'react-icons/fa'
import { MdContentCopy, MdEdit, MdOutlineUploadFile } from 'react-icons/md'
import Select from 'react-select'
import { Link, useLocation } from 'wouter'
import * as Yup from 'yup'

export default function FTTeixiduriaDetail({ params }) {
  // TODO: Logo GRS

  // Referéncias:
  const hiddenFileInput = useRef(null)

  // Context:
  const { setTitle } = useContext(TitleContext)
  const { user } = useContext(UserContext)
  const [location, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)

  // useFetch()
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallGetMachines, setApiCallGetMachines] = useFetch()
  const [getApiCallGetSemiFinished, setApiCallGetSemiFinished] = useFetch()
  const [getApiCallGetUser, setApiCallGetUser] = useFetch()
  const [getApiCallGetUsers, setApiCallGetUsers] = useFetch()
  const [getApiCallGetRequest, setApiCallGetRequest] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()
  const [getApiCallPostDocument, setApiCallPostDocument] = useFetch()
  const [getApiCallDeleteDocument, setApiCallDeleteDocument] = useFetch()
  const [getApiCallPutCopy, setApiCallPutCopy] = useFetch()

  // Estados:
  const [documentsArray, setDocumentsArray] = useState([])
  const [canRequest, setCanRequest] = useState(false)
  const [canCopy, setCanCopy] = useState(false)
  const [canDelete, setCanDelete] = useState(false)
  const [canCreate, setCanCreate] = useState(false)
  const [canCheckHistory, setCanCheckHistory] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [users, setUsers] = useState(null)
  const [getAllowEdit, setAllowEdit] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [getIsGRS, setIsGRS] = useState(false)

  // Variables:
  const readOnly = user.Role.Id === 2
  const isNew = !!(params.id === 'nou')
  const commonInputStyles =
    'px-2 py-1 text-base border border-gray-300 rounded-sm outline-none appearance-none'

  const now = new Date()
  const day = now.getDate().toString().padStart(2, '0')
  const month = (now.getMonth() + 1).toString().padStart(2, '0')
  const year = now.getFullYear()
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}h`

  const [getFieldsState, setFieldsState] = useState({
    Id: 0,

    // Campos apartado 'Descripció':
    SemiFinished: '',
    SemiFinishedLong: '',
    SecurityStep: false,
    SecurityStepDescription: '',
    Is3D: false,
    Press3D: '',
    EncryptedCode: '',
    TiedDescription: '',
    Repetitions: '',
    RepetitionsV2: '',
    RepetitionsTolerance: '1',
    RepetitionsToleranceV2: '1',
    Columns: '',
    ColumnsV2: '',
    ColumnsTolerance: '1',
    ColumnsToleranceV2: '1',
    Weight: '',
    WeightV2: '',
    WeightTolerance: '3',
    WeightToleranceV2: '3',
    Width: '',
    WidthV2: '',
    WidthTolerance: '3',
    WidthToleranceV2: '3',
    Thickness: '',
    ThicknessV2: '',
    ThicknessTolerance: '0.02',
    ThicknessToleranceV2: '0.02',
    RDTNominal: '',
    RDTNominalV2: '',
    RDTNominalTolerance: '',
    RDTNominalToleranceV2: '',
    Machine: '',
    MachineCode: '',
    FoldType: '',
    GG: '',
    O: '',
    NGame: '',
    Needle: '',
    Rpm: 0,
    RpmMin: 0,
    TurnsPerKg: '',
    TurnsPerKgTolerance: '2',
    TurnsPerPart: '',
    TurnsPerPartByOperator: '',
    TurnsPerPartByOperatorTolerance: '100',
    KgH_100: '',
    KgH_80: '',
    KgD_100: '',
    KgD_80: '',
    KgPart: '',
    KgPartTolerance: '2',

    // Campos apartado 'Plegador / Calandra':
    Synchronization: '',
    Formation: '',
    DishHeight1: '',
    DishHeight2: '',
    DishDisplacement1: '',
    DishDisplacement2: '',

    // Campos apartado 'Pinyols / Politja':
    Piston1: '',
    Piston2: '',
    Piston3: '',
    Piston4: '',
    Piston5: '',
    Piston6: '',
    Piston7: '',
    Piston8: '',
    Piston9: '',
    Piston10: '',
    JUM1: '',
    JUM2: '',
    JUM3: '',
    JUM4: '',
    PistonTolerance1: '5',
    PistonTolerance2: '5',
    PistonTolerance3: '5',
    PistonTolerance4: '5',

    // Campos apartado 'Graduació del punt i consum':
    CylinderHeight1: '',
    CylinderHeight2: '',
    PositionTurntables: '',
    PositionGuiafils: '',

    // Campos apartado 'Alçada i sincronisme':
    Calandra: '',
    Delta: '',
    FolderingPiston1: '',
    FolderingPiston2: '',
    FolderingPiston3: '',
    FolderingPiston4: '',
    FolderTension1: '',
    FolderTension1_v2: '',
    FolderTension2: '',
    FolderTension2_v2: '',
    FolderTension3: '',
    FolderTension4: '',
    P1: '',
    P2: '',
    P3: '',
    PistonJUM: '',
    PistonJUM_v2: '',
    PistonJUM2: '',
    PistonJUM2_v2: '',
    RollingRoller1: '',
    RollingRoller1_v2: '',
    RollingRoller2: '',
    RollingRoller2_v2: '',
    RollingRoller3: '',
    RollingRoller4: '',
    TensionMayer: '',
    PositionMonarch: '',
    PositionTerrot1: '',
    PositionTerrot2: '',

    // Campos apartado 'Observacions / Notes':
    DestacableText: '',
    Obseravtion: '',
    EditionNumber: 1,
    EditionDate: '',
    UserDone: '',
    UserApproved: '',
    PassTensiometer: false,
    TSWeavingThreads: [],
    TSWeavingDocuments: [],
    TSWeavingMachineGroups: []
  })

  const formatDate = (dateString) => {
    const utcDate = new Date(dateString)
    const userTimeZoneOffset = new Date().getTimezoneOffset()
    const userLocalTime = new Date(
      utcDate.getTime() - userTimeZoneOffset * 60000
    )

    // Obtener los componentes de la fecha
    const day = ('0' + userLocalTime.getDate()).slice(-2)
    const month = ('0' + (userLocalTime.getMonth() + 1)).slice(-2)
    const year = userLocalTime.getFullYear()

    // Construir la cadena de fecha formateada
    const formattedLocalTime = `${day}-${month}-${year}`

    return formattedLocalTime
  }

  // #########################################################################
  // TECHNICALSHEET (FITXA TÈCNICA)
  // #########################################################################
  // CRUD: GET (FT Teixiduria + Usuarios)
  useEffect(() => {
    const title = {
      name: isNew ? `Crear fitxa tècnica` : `Fitxa Tècnica`,
      buttons: [
        {
          name: 'Tornar',
          link: '/ft-teixiduria'
        }
      ]
    }
    setTitle(title)

    const apiCallUsers = {
      url: `user/active`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    setApiCallGetUsers(apiCallUsers)
  }, [])

  // Get users api call response
  useEffect(() => {
    if (getApiCallGetUsers.data && !users) {
      setUsers(getApiCallGetUsers.data)

      const apiCallGetUser = {
        url: `user/${user.Id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetUser(apiCallGetUser)
    }
  }, [getApiCallGetUsers.data])

  // Get user api call response
  useEffect(() => {
    if (getApiCallGetUser.data && !currentUser) {
      setTitle((prevTitle) => ({
        ...prevTitle,
        name: isNew ? `Crear fitxa tècnica` : `Fitxa Tècnica`
      }))

      // ##################################################################################

      setCurrentUser(getApiCallGetUser.data)

      // ##################################################################################

      // Permissions
      let allow = false
      let allowRequest = false
      let allowCheckHistory = false
      let allowCopy = false
      let allowDelete = false
      let allowCreate = false
      const userId = user.Id
      const userRole = user.Role.Id

      if (
        userRole === 1 &&
        (userId === 818 ||
          userId === 7 ||
          userId === 4 ||
          userId === 11 ||
          userId === 8)
      ) {
        allow = true
        allowRequest = true
        allowCheckHistory = true
        allowCopy = true
        allowDelete = true
        allowCreate = true
      } else {
        allow = false
        allowRequest = false
        allowCheckHistory = false
        allowCopy = false
        allowDelete = false
        allowCreate = false
      }

      setAllowEdit(allow)
      setCanRequest(allowRequest)
      setCanCheckHistory(allowCheckHistory)
      setCanCopy(allowCopy && !isNew)
      setCanDelete(allowDelete && !isNew)
      setCanCreate(allowCreate && isNew)
      // Permissions

      // ##################################################################################

      if (!isNew) {
        const apiCallGet = {
          url: `tsweaving/${params.id}`,
          method: 'GET',
          messageKo: 'Error al recuperar dades del ECAP!'
        }
        setApiCallGet(apiCallGet)
      } else {
        const apiCallGetMachines = {
          url: `tsweaving/machines`,
          method: 'GET',
          messageKo: 'Error al recuperar dades del ECAP'
        }
        setApiCallGetMachines(apiCallGetMachines)

        const apiCallGetSemiFinished = {
          url: `semifinished`,
          method: 'GET',
          messageKo: 'Error al recuperar dades del ECAP'
        }
        setApiCallGetSemiFinished(apiCallGetSemiFinished)
      }
    }
  }, [getApiCallGetUser.data, location])

  useEffect(() => {
    if (getApiCallGetSemiFinished?.data) {
      console.log(
        '🚀 ~ useEffect ~ getApiCallGetSemiFinished?.data:',
        getApiCallGetSemiFinished?.data
      )
    }
  }, [getApiCallGetSemiFinished.data])

  useEffect(() => {
    if (getApiCallGetMachines?.data) {
      console.log(
        '🚀 ~ useEffect ~ getApiCallGetMachines?.data:',
        getApiCallGetMachines?.data
      )
    }
  }, [getApiCallGetMachines.data])

  // Get technicalsheet api call response
  useEffect(() => {
    if (getApiCallGet.data && currentUser && getFieldsState.Id === 0) {
      const id = getApiCallGet.data?.Id
      const apiCallGetRequest = {
        url: `tsweaving/request/last/${id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetRequest(apiCallGetRequest)
    }
  }, [getApiCallGet.data, currentUser])

  // Get technicalsheet request api call response
  useEffect(() => {
    if (getApiCallGet.data && currentUser && getFieldsState.Id === 0) {
      const apiData = JSON.parse(JSON.stringify(getApiCallGet.data))
      setFieldsState({
        Id: getApiCallGet.data?.Id,
        SemiFinished: getApiCallGet.data?.SemiFinished,
        SemiFinishedLong: getApiCallGet.data?.SemiFinishedLong,
        SecurityStep: getApiCallGet.data?.SecurityStep,
        SecurityStepDescription: getApiCallGet.data?.SecurityStepDescription,
        Is3D: getApiCallGet.data?.Is3D,
        Press3D: getApiCallGet.data?.Press3D,
        EncryptedCode: getApiCallGet.data?.EncryptedCode,
        TiedDescription: getApiCallGet.data?.TiedDescription,
        Repetitions: Number(
          getApiCallGet.data?.Repetitions?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Repetitions % 1 === 0 ? 0 : 2),
        RepetitionsV2: Number(
          getApiCallGet.data?.RepetitionsV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RepetitionsV2 % 1 === 0 ? 0 : 2),
        RepetitionsTolerance: Number(
          getApiCallGet.data?.RepetitionsTolerance?.replace(/,/g, '.') ?? '1'
        ).toFixed(getApiCallGet.data?.RepetitionsTolerance % 1 === 0 ? 0 : 2),
        RepetitionsToleranceV2: Number(
          getApiCallGet.data?.RepetitionsToleranceV2?.replace(/,/g, '.') ?? '1'
        ).toFixed(getApiCallGet.data?.RepetitionsToleranceV2 % 1 === 0 ? 0 : 2),
        Columns: Number(
          getApiCallGet.data?.Columns?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Columns % 1 === 0 ? 0 : 2),
        ColumnsV2: Number(
          getApiCallGet.data?.ColumnsV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.ColumnsV2 % 1 === 0 ? 0 : 2),
        ColumnsTolerance: Number(
          getApiCallGet.data?.ColumnsTolerance?.replace(/,/g, '.') ?? '1'
        ).toFixed(getApiCallGet.data?.ColumnsTolerance % 1 === 0 ? 0 : 2),
        ColumnsToleranceV2: Number(
          getApiCallGet.data?.ColumnsToleranceV2?.replace(/,/g, '.') ?? '1'
        ).toFixed(getApiCallGet.data?.ColumnsToleranceV2 % 1 === 0 ? 0 : 2),
        Weight: Number(
          getApiCallGet.data?.Weight?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Weight % 1 === 0 ? 0 : 2),
        WeightV2: Number(
          getApiCallGet.data?.WeightV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.WeightV2 % 1 === 0 ? 0 : 2),
        WeightTolerance: Number(
          getApiCallGet.data?.WeightTolerance?.replace(/,/g, '.') ?? '3'
        ).toFixed(getApiCallGet.data?.WeightTolerance % 1 === 0 ? 0 : 2),
        WeightToleranceV2: Number(
          getApiCallGet.data?.WeightToleranceV2?.replace(/,/g, '.') ?? '3'
        ).toFixed(getApiCallGet.data?.WeightToleranceV2 % 1 === 0 ? 0 : 2),
        Width: Number(
          getApiCallGet.data?.Width?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Width % 1 === 0 ? 0 : 2),
        WidthV2: Number(
          getApiCallGet.data?.WidthV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.WidthV2 % 1 === 0 ? 0 : 2),
        WidthTolerance: Number(
          getApiCallGet.data?.WidthTolerance?.replace(/,/g, '.') ?? '3'
        ).toFixed(getApiCallGet.data?.WidthTolerance % 1 === 0 ? 0 : 2),
        WidthToleranceV2: Number(
          getApiCallGet.data?.WidthToleranceV2?.replace(/,/g, '.') ?? '3'
        ).toFixed(getApiCallGet.data?.WidthToleranceV2 % 1 === 0 ? 0 : 2),
        Thickness: Number(
          getApiCallGet.data?.Thickness?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Thickness % 1 === 0 ? 0 : 2),
        ThicknessV2: Number(
          getApiCallGet.data?.ThicknessV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.ThicknessV2 % 1 === 0 ? 0 : 2),
        ThicknessTolerance: Number(
          getApiCallGet.data?.ThicknessTolerance?.replace(/,/g, '.') ?? '0.02'
        ).toFixed(getApiCallGet.data?.ThicknessTolerance % 1 === 0 ? 0 : 2),
        ThicknessToleranceV2: Number(
          getApiCallGet.data?.ThicknessToleranceV2?.replace(/,/g, '.') ?? '0.02'
        ).toFixed(getApiCallGet.data?.ThicknessToleranceV2 % 1 === 0 ? 0 : 2),
        RDTNominal: Number(
          getApiCallGet.data?.RDTNominal?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RDTNominal % 1 === 0 ? 0 : 2),
        RDTNominalV2: Number(
          getApiCallGet.data?.RDTNominalV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RDTNominalV2 % 1 === 0 ? 0 : 2),
        RDTNominalTolerance: Number(
          getApiCallGet.data?.RDTNominalTolerance?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RDTNominalTolerance % 1 === 0 ? 0 : 2),
        RDTNominalToleranceV2: Number(
          getApiCallGet.data?.RDTNominalToleranceV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RDTNominalToleranceV2 % 1 === 0 ? 0 : 2),
        LaboratoryComment: getApiCallGet.data?.LaboratoryComment,
        Machine: getApiCallGet.data?.Machine,
        MachineCode: getApiCallGet.data?.MachineCode,
        FoldType: getApiCallGet.data?.FoldType,
        GG: getApiCallGet.data?.GG,
        O: getApiCallGet.data?.O,
        NGame: getApiCallGet.data?.NGame,
        Needle: getApiCallGet.data?.Needle,
        Rpm: Number(
          getApiCallGet.data?.Rpm?.toString().replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Rpm % 1 === 0 ? 0 : 1),
        RpmMin: Number(
          getApiCallGet.data?.RpmMin?.toString().replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RpmMin % 1 === 0 ? 0 : 1),
        TurnsPerKg: Math.round(
          Number(getApiCallGet.data?.TurnsPerKg?.replace(/,/g, '.') ?? 0)
        ),
        TurnsPerKgTolerance: Math.round(
          Number(getApiCallGet.data?.TurnsPerKgTolerance ?? '2')
        ),
        TurnsPerPart: Math.round(
          Number(getApiCallGet.data?.TurnsPerPart?.replace(/,/g, '.') ?? 0)
        ),
        TurnsPerPartByOperator: Math.round(
          Number(
            getApiCallGet.data?.TurnsPerPartByOperator?.replace(/,/g, '.') ?? 0
          )
        ),
        TurnsPerPartByOperatorTolerance: Math.round(
          Number(getApiCallGet.data?.TurnsPerPartByOperatorTolerance ?? '100')
        ),
        KgH_100: getApiCallGet.data?.KgH_100,
        KgH_80: getApiCallGet.data?.KgH_80,
        KgD_100: getApiCallGet.data?.KgD_100,
        KgD_80: getApiCallGet.data?.KgD_80,
        KgPart: Number(
          getApiCallGet.data?.KgPart?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.KgPart % 1 === 0 ? 0 : 1),
        KgPartTolerance: Number(
          getApiCallGet.data?.KgPartTolerance?.replace(/,/g, '.') ?? '2'
        ).toFixed(getApiCallGet.data?.KgPartTolerance % 1 === 0 ? 0 : 1),
        Synchronization: getApiCallGet.data?.Synchronization,
        Formation: getApiCallGet.data?.Formation,
        DishHeight1: getApiCallGet.data?.DishHeight1,
        DishHeight2: getApiCallGet.data?.DishHeight2,
        DishDisplacement1: getApiCallGet.data?.DishDisplacement1,
        DishDisplacement2: getApiCallGet.data?.DishDisplacement2,
        Piston1: getApiCallGet.data?.Piston1,
        Piston2: getApiCallGet.data?.Piston2,
        Piston3: getApiCallGet.data?.Piston3,
        Piston4: getApiCallGet.data?.Piston4,
        Piston5: getApiCallGet.data?.Piston5,
        Piston6: getApiCallGet.data?.Piston6,
        Piston7: getApiCallGet.data?.Piston7,
        Piston8: getApiCallGet.data?.Piston8,
        Piston9: getApiCallGet.data?.Piston9,
        Piston10: getApiCallGet.data?.Piston10,
        JUM1: getApiCallGet.data?.JUM1,
        JUM2: getApiCallGet.data?.JUM2,
        JUM3: getApiCallGet.data?.JUM3,
        JUM4: getApiCallGet.data?.JUM4,
        PistonTolerance1: getApiCallGet.data?.PistonTolerance1 ?? '5',
        PistonTolerance2: getApiCallGet.data?.PistonTolerance2 ?? '5',
        PistonTolerance3: getApiCallGet.data?.PistonTolerance3 ?? '5',
        PistonTolerance4: getApiCallGet.data?.PistonTolerance4 ?? '5',
        CylinderHeight1: getApiCallGet.data?.CylinderHeight1,
        CylinderHeight2: getApiCallGet.data?.CylinderHeight2,
        PositionTurntables: getApiCallGet.data?.PositionTurntables,
        PositionGuiafils: getApiCallGet.data?.PositionGuiafils,
        Calandra: getApiCallGet.data?.Calandra,
        Delta: getApiCallGet.data?.Delta,
        FolderingPiston1: getApiCallGet.data?.FolderingPiston1,
        FolderingPiston2: getApiCallGet.data?.FolderingPiston2,
        FolderingPiston3: getApiCallGet.data?.FolderingPiston3,
        FolderingPiston4: getApiCallGet.data?.FolderingPiston4,
        FolderTension1: getApiCallGet.data?.FolderTension1,
        FolderTension2: getApiCallGet.data?.FolderTension2,
        FolderTension3: getApiCallGet.data?.FolderTension3,
        FolderTension4: getApiCallGet.data?.FolderTension4,
        P1: getApiCallGet.data?.P1,
        P2: getApiCallGet.data?.P2,
        P3: getApiCallGet.data?.P3,
        PistonJUM: getApiCallGet.data?.PistonJUM,
        PistonJUM2: getApiCallGet.data?.PistonJUM2,
        RollingRoller1: getApiCallGet.data?.RollingRoller1,
        RollingRoller2: getApiCallGet.data?.RollingRoller2,
        RollingRoller3: getApiCallGet.data?.RollingRoller3,
        RollingRoller4: getApiCallGet.data?.RollingRoller4,
        TensionMayer: getApiCallGet.data?.TensionMayer,
        PositionMonarch: getApiCallGet.data?.PositionMonarch,
        PositionTerrot1: getApiCallGet.data?.PositionTerrot1,
        PositionTerrot2: getApiCallGet.data?.PositionTerrot2,
        DestacableText: getApiCallGet.data?.DestacableText,
        Obseravtion: getApiCallGet.data?.Obseravtion,
        EditionNumber: getApiCallGet.data?.EditionNumber,
        EditionDate: getApiCallGet.data?.EditionDate
          ? formatDate(getApiCallGet.data?.EditionDate)
          : '',
        UserDone: getApiCallGet.data?.UserDone,
        UserApproved: getApiCallGet.data?.UserApproved,
        PassTensiometer: getApiCallGet.data?.PassTensiometer,
        TSWeavingThreads: getApiCallGet.data?.TSWeavingThreads,
        TSWeavingDocuments: getApiCallGet.data?.TSWeavingDocuments,
        TSWeavingMachineGroups: getApiCallGet.data?.TSWeavingMachineGroups
      })

      if (
        getApiCallGet.data?.TSWeavingThreads !== undefined &&
        getApiCallGet.data?.TSWeavingThreads !== null
      ) {
        const foundThreadGRS = getApiCallGet?.data?.TSWeavingThreads.some(
          (element) => {
            return element.GRS === true
          }
        )

        setIsGRS(foundThreadGRS)
      } else {
        setIsGRS(false)
      }
    }
  }, [getApiCallGetRequest.data])

  // #########################################################################
  // DOCUMENTS
  // #########################################################################
  // CRUD: POST (FT Teixiduria Documento)
  const uploadDocument = (e) => {
    const files = e.currentTarget.files
    //* Handle multiple files

    const formData = new FormData()
    if (files.length > 1) {
      Array.from(files).forEach((file) => {
        formData.append('document', file)
      })
    } else {
      formData.append('document', files[0])
    }

    const tsweavingParams = {
      url: `tsweaving/${getApiCallPost.data?.Id}/documents`,
      method: 'POST',
      formData: formData,
      messageOk: 'Documento subido!',
      messageKo: 'Error al subir documentos'
    }

    setApiCallPostDocument(tsweavingParams)

    setIsUploading(true)
  }

  useEffect(() => {
    if (getApiCallPostDocument.data && isUploading === true) {
      const newDocuments = JSON.parse(getApiCallPostDocument.data)
      setDocumentsArray(newDocuments)
      setIsUploading(false)
    }
  }, [getApiCallPostDocument])

  // CRUD: DELETE (FT Teixiduria Documento)
  const deleteDocument = (id) => {
    const deleteDocumentParams = {
      url: `tsweaving/${params.id}/document/${id}`,
      method: 'DELETE',
      messageOk: 'Documento eliminado!',
      messageKo: 'Error al eliminar documentos'
    }
    setApiCallDeleteDocument(deleteDocumentParams)
    findDeletedDocument(id)
    handleModal('hide')
  }

  const findDeletedDocument = (id) => {
    const newDocumentsArray = documentsArray.filter(
      (document) => document.Id !== id
    )
    setDocumentsArray(newDocumentsArray)
  }

  // ############################################################

  const renderTableData = () => {
    if (documentsArray?.length > 0)
      return documentsArray.map((document) => {
        return (
          <td key={document.Id} className="border-b border-gray-300">
            <button className="block w-auto p-1 m-2 text-sm rounded cursor-pointer focus:shadow-outline hover:bg-gray-300">
              <a
                href={`${
                  process.env.REACT_APP_BASE_UPLOADS_URL
                }/uploads/tsweaving/${
                  getFieldsState.Id
                }/documents/${encodeURIComponent(document.Name)}`}
                target="_blank"
                rel="noreferrer"
              >
                {document.Name}
              </a>
            </button>
            <button
              disabled={readOnly}
              className={`m-2 mx-auto p-2 rounded-lg w-auto block font-medium text-sm     focus:shadow-outline hover:bg-gray-300 ${
                readOnly && 'bg-gray-300'
              } ${readOnly ? 'cursor-default' : 'cursor-pointer'}`}
              onClick={() =>
                handleModal(
                  <DeleteModal
                    deleteUserOrFT={deleteDocument}
                    element="fitxer"
                    id={document.Id}
                    closeModal={() => handleModal('hide')}
                  />
                )
              }
            >
              <FaTrash size={17} />
            </button>
          </td>
        )
      })
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  // Post
  const handleSubmit = (values) => {
    const preparedValues = JSON.parse(JSON.stringify(values))

    const apiCallPost = {
      url: 'tsweaving/save/first',
      method: 'POST',
      body: preparedValues,
      messageKo: `Error en la sol·licitud!`
    }
    setApiCallPost(apiCallPost)
  }

  // Get post/put response
  useEffect(() => {
    if (
      getApiCallPost?.data &&
      getApiCallPost?.data?.Id !== null &&
      getApiCallPost?.data?.Id !== 0
    ) {
      setCurrentUser(false)
      setLocation(`/ft-teixiduria/${getApiCallPost?.data?.Id}`)
    }
  }, [getApiCallPost.data])

  // Formik validation
  const validationSchema = Yup.object().shape({
    SemiFinished: Yup.string().required(
      'No es pot crear una fitxa sense especificar el camp semielaborat.'
    ),
    Machine: Yup.string().required(
      'No es pot crear una fitxa sense especificar el camp màquina.'
    )
  })

  return !isNew ? (
    users !== null && getFieldsState.SemiFinished ? (
      <>
        {/* Options bar */}
        <div
          className="sticky top-0 z-50 flex items-end justify-end w-full col-span-2 px-4 py-3 text-sm uppercase border-b-2 print:hidden border-charcoal"
          style={{ backdropFilter: 'blur(25px)' }}
        >
          {/* ELIMINAR / COPIAR / IMPRIMIR / HISTORIAL / SOL·LICITAR */}
          <div className="inline-flex items-end space-x-2">
            {/* ELIMINIAR */}
            {canDelete && (
              <button
                type="button"
                className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center uppercase transition duration-300 bg-white border-2 rounded d-flex text-red-600 border-red-600 text-md font-ms-bold hover:bg-red-600 hover:text-white print:hidden"
                onClick={() =>
                  handleModal(
                    <DeleteModal
                      id={params.id}
                      closeModal={() => handleModal('hide')}
                    />
                  )
                }
              >
                <MdContentCopy className="xl:mr-2" size={22} />
                <span className="hidden xl:inline-flex">Eliminar</span>
              </button>
            )}

            {/* COPIAR */}
            {canCopy && (
              <button
                type="button"
                className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-gray-600 uppercase transition duration-300 bg-white border-2 border-gray-600 rounded d-flex text-md font-ms-bold hover:bg-gray-600 hover:text-white print:hidden"
                onClick={() =>
                  handleModal(
                    <CloneFTModal
                      id={params.id}
                      closeModal={() => handleModal('hide')}
                    />
                  )
                }
              >
                <MdContentCopy className="xl:mr-2" size={22} />
                <span className="hidden xl:inline-flex">Copiar</span>
              </button>
            )}

            {/* IMPRIMIR */}
            <button
              type="button"
              className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-gray-600 uppercase transition duration-300 bg-white border-2 border-gray-600 rounded d-flex text-md font-ms-bold hover:bg-gray-600 hover:text-white print:hidden"
              onClick={() => window.print()}
            >
              <AiOutlinePrinter className="xl:mr-2" size={25} />
              <span className="hidden xl:inline-flex">Imprimir</span>
            </button>

            {/* HISTORIAL */}
            {canCheckHistory && (
              <Link
                to={`/ft-teixiduria/requests/${params.id}`}
                className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center uppercase transition duration-300 bg-white border-2 rounded d-flex text-amber-600 border-amber-600 text-md font-ms-bold hover:bg-amber-600 hover:text-white print:hidden"
              >
                <AiOutlineHistory className="xl:mr-2" size={22} />
                <span className="hidden xl:inline-flex">Historial</span>
              </Link>
            )}

            {/* SOL·LICITAR */}
            {canRequest && (
              <Link
                to={`/ft-teixiduria/request-create/${encodeURIComponent(
                  getFieldsState.Id
                )}`}
                className="relative inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-teal-500 uppercase transition duration-300 bg-white border-2 border-teal-500 rounded text-md font-ms-bold hover:bg-teal-500 hover:text-white print:hidden"
              >
                <MdEdit className="xl:mr-2" size={22} />
                <span className="hidden xl:inline-flex">Sol·licitar</span>
              </Link>
            )}
          </div>
        </div>

        {/* Data */}
        <div className="p-4 space-y-2 print:p-0">
          {/* FITXA TÈCNICA */}
          <div
          // TODO
          // style={{
          //   pageBreakAfter: 'always',
          //   pageBreakInside: 'avoid'
          // }}
          >
            {/* Capçalera d'impressió */}
            <h1 className="hidden w-full print:flex print:justify-around print:items-center print:pb-2 print:m-0">
              <span className="flex items-center justify-center h-full text-2xl font-ms-bold">
                FITXA TÈCNICA TEIXIDURIA
              </span>

              <span className="flex flex-row items-center justify-end h-full space-x-6">
                <span className="flex flex-col items-start justify-center h-full text-base">
                  <span>{formattedDateTime}</span>
                  <span>Format-IATF-013-C</span>
                </span>

                <span>
                  {getIsGRS === true && (
                    <img src={logoGRS} width={90} height={90} />
                  )}
                </span>
              </span>
            </h1>

            <div className="relative grid grid-flow-row grid-cols-12 gap-2 print:gap-1 auto-rows-max">
              {/* DESCRIPCIÓ */}
              <Block
                title="Descripció"
                className="flex flex-col col-span-12"
                values={getFieldsState}
              />

              {/* PLEGADOR / CALANDRA: */}
              <Block
                title="Plegador / Calandra"
                className="flex flex-col col-span-9"
                values={getFieldsState}
              />

              {/* PINYONS / POLITJA */}
              <Block
                title="Pinyons / Politja"
                className="flex flex-col col-span-3"
                values={getFieldsState}
              />

              {/* GRADUACIÓ DEL PUNT I CONSUM */}
              <Block
                title="Graduació del punt i consum"
                className="flex flex-col col-span-9"
                values={getFieldsState}
              />

              {/* ALÇADA I SINCRONISME */}
              <Block
                title="Alçada i sincronisme"
                className="flex flex-col col-span-3"
                values={getFieldsState}
              />

              {/* OBSERVACIONS / NOTES DE FITXA TÈCNICA DE TEIXIDURIA */}
              <Block
                title="Observacions / Notes"
                className="flex flex-col col-span-12"
                values={getFieldsState}
              />
            </div>

            {/* FITXERS ADJUNTS */}
            {getAllowEdit && getApiCallGet?.data && (
              <div className="flex items-center w-full mt-2 h-100 print:hidden">
                <table className="mr-8 bg-white border border-gray-300 rounded-sm shadow-md ">
                  <thead>
                    <tr>
                      <th
                        className="relative w-64 h-12 px-4 text-sm text-left text-white font-ms-bold bg-primary"
                        colSpan={documentsArray?.length}
                      >
                        <div className="flex flex-row items-center justify-between">
                          <span>FITXERS ADJUNTS</span>
                          <span className="flex items-center justify-center">
                            <button
                              className={`font-ms-semi px-1 rounded-sm transition-colors text-white duration-300 focus:shadow-outline hover:bg-white hover:text-primary ${
                                readOnly
                                  ? 'bg-gray-300 cursor-default'
                                  : 'cursor-pointer'
                              }`}
                              onClick={handleClick}
                            >
                              <MdOutlineUploadFile size={30} />
                            </button>
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>{renderTableData()}</tr>
                  </tbody>
                </table>

                <input
                  ref={hiddenFileInput}
                  accept=".pdf, .xlsx"
                  type="file"
                  name="file"
                  multiple="multiple"
                  className=" h-1/6   hidden py-2 ml-2 p-2.5 text-primary font-ms-semi transition-colors duration-150  bg-white border border-primary rounded-lg focus:shadow-outline hover:bg-secondary hover:text-white cursor-pointer text-grey-darker "
                  onChange={(e) => uploadDocument(e)}
                />
              </div>
            )}
          </div>

          {/* ÚLTIMA MODIFICACIÓ */}
          {getApiCallGetRequest?.data &&
            getApiCallGetRequest?.data?.RequestId !== 0 && (
              <div
                className="print:hidden"
                style={{
                  pageBreakBefore: 'always',
                  pageBreakInside: 'avoid'
                }}
              >
                <div className="relative grid grid-flow-row grid-cols-12 gap-2 auto-rows-max">
                  <Block
                    title="Última modificació"
                    className="flex flex-col col-span-12"
                    values={getApiCallGetRequest.data}
                  />
                </div>
              </div>
            )}
        </div>
      </>
    ) : (
      <div className="inline-flex items-center justify-start w-full p-4">
        <CgSpinner size={40} className="text-charcoal animate-spin" />
        <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
      </div>
    )
  ) : users !== null &&
    getApiCallGetMachines?.data !== null &&
    getApiCallGetMachines?.data !== undefined ? (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={getFieldsState}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true)
        handleSubmit(values)
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        props,
        handleBlur,
        handleChange,
        submitForm,
        isSubmitting
      }) => (
        <Form className="p-4 space-y-2 print:p-0">
          {/* FITXA TÈCNICA */}
          {/* Capçalera d'impressió */}
          <div className="relative grid grid-flow-row grid-cols-12 gap-2 print:gap-1 auto-rows-max">
            {/* DESCRIPCIÓ */}
            <div className="flex items-start justify-center col-span-12 mt-12 mb-10 space-x-2 uppercase">
              <button
                title="Crear"
                type="submit"
                className={`text-teal-500 bg-white hover:bg-teal-500 border-2 border-teal-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                disabled={isSubmitting}
              >
                <MdEdit className="xl:mr-2" size={25} />
                <span>Crear</span>
              </button>
            </div>

            <div className="flex flex-col col-span-12 bg-white rounded border-2 print:border border-blue-200 border-collapse shadow-md print:shadow-none">
              <div className="flex flex-row items-center justify-between w-full text-sm uppercase bg-blue-200 font-ms-bold">
                <span className="py-2 pl-2 print:py-0.5 print:px-1">
                  Descripció
                </span>
              </div>

              <div className="grid grid-cols-12 p-3 divide-blue-100 grow gap-y-3">
                {/* Semielaborat */}
                <div className="col-span-12 space-y-1">
                  <div className="col-span-12 text-sm font-ms-semi">
                    Semielaborat (
                    <span className="text-red-600">
                      Format curt / Format llarg
                    </span>
                    ):
                  </div>
                  <div className="flex flex-col content-start items-start col-span-12">
                    <div className="w-full flex items-center space-x-2">
                      <Select
                        className="flex-grow w-5/12 leading-tight text-grey-darker"
                        onChange={(e) => {
                          setFieldValue('SemiFinished', e.semi)
                          setFieldValue('SemiFinishedLong', e.cod_art)
                        }}
                        name="SemiFinished"
                        placeholder="Seleccionar..."
                        options={getApiCallGetSemiFinished?.data}
                        getOptionLabel={(o) => o.semi + ' / ' + o.cod_art}
                        getOptionValue={(o) => o.semi}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderRadius: '0.125rem',
                            minHeight: '2rem',
                            cursor: 'pointer',
                            boxShadow: 'none'
                          }),
                          valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            height: '100%',
                            padding: '0.25rem 0.5rem',
                            position: 'static',
                            cursor: 'pointer',
                            rowGap: '0.25rem'
                          }),
                          multiValue: (baseStyles) => ({
                            ...baseStyles,
                            height: '100%',
                            margin: '0 0.25rem 0 0',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            margin: '0',
                            paddingTop: '0',
                            paddingBottom: '0',
                            cursor: 'pointer'
                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            marginLeft: '0',
                            marginRight: '0'
                          }),
                          indicatorsContainer: (baseStyles) => ({
                            ...baseStyles,
                            cursor: 'pointer'
                          }),
                          dropdownIndicator: (baseStyles) => ({
                            ...baseStyles,
                            padding: '0px 8px'
                          })
                        }}
                        value={getApiCallGetMachines?.data?.find(
                          (option) => option?.semi === values?.SemiFinished
                        )}
                      />
                    </div>

                    {/* Esta validación es necesaria porque 'ErrorMessage' a veces se queda visible, aunque ya se haya llenado el campo. 
                    Esto sucede porque estamos usando un react-select que no es parte de Formik. */}
                    {values?.SemiFinished === null ||
                      values?.SemiFinished === undefined ||
                      (values?.SemiFinished === '' && (
                        <div className="w-full">
                          <ErrorMessage
                            name="SemiFinished"
                            render={(message) => (
                              <span className="w-full text-xs font-semibold text-red-600">
                                {message}
                              </span>
                            )}
                          />
                        </div>
                      ))}
                  </div>
                </div>

                {/* Màquina */}
                <div className="col-span-12 space-y-1">
                  <div className="col-span-12 text-sm font-ms-semi">
                    Màquina (
                    <span className="text-red-600">
                      Codi màquina / Màquina / Plegador
                    </span>
                    ):
                  </div>
                  <div className="flex flex-col content-start items-start col-span-12">
                    <div className="w-full flex items-center space-x-2">
                      <Select
                        className="flex-grow w-5/12 leading-tight text-grey-darker"
                        onChange={(e) => {
                          setFieldValue('Machine', e.desc_sec)
                          setFieldValue('MachineCode', e.cod_maq)
                          setFieldValue('FoldType', e.plegador)
                        }}
                        name="MachineCode"
                        placeholder="Seleccionar..."
                        options={getApiCallGetMachines?.data}
                        getOptionLabel={(o) =>
                          o.cod_maq + ' / ' + o.desc_sec + ' / ' + o.plegador
                        }
                        getOptionValue={(o) => o.cod_maq}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderRadius: '0.125rem',
                            minHeight: '2rem',
                            cursor: 'pointer',
                            boxShadow: 'none'
                          }),
                          valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            height: '100%',
                            padding: '0.25rem 0.5rem',
                            position: 'static',
                            cursor: 'pointer',
                            rowGap: '0.25rem'
                          }),
                          multiValue: (baseStyles) => ({
                            ...baseStyles,
                            height: '100%',
                            margin: '0 0.25rem 0 0',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            margin: '0',
                            paddingTop: '0',
                            paddingBottom: '0',
                            cursor: 'pointer'
                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            marginLeft: '0',
                            marginRight: '0'
                          }),
                          indicatorsContainer: (baseStyles) => ({
                            ...baseStyles,
                            cursor: 'pointer'
                          }),
                          dropdownIndicator: (baseStyles) => ({
                            ...baseStyles,
                            padding: '0px 8px'
                          })
                        }}
                        value={getApiCallGetMachines?.data?.find(
                          (option) => option?.cod_maq === values?.MachineCode
                        )}
                      />
                    </div>

                    {/* Esta validación es necesaria porque 'ErrorMessage' a veces se queda visible, aunque ya se haya llenado el campo. 
                    Esto sucede porque estamos usando un react-select que no es parte de Formik. */}
                    {values?.Machine === null ||
                      values?.Machine === undefined ||
                      (values?.Machine === '' && (
                        <div className="w-full">
                          <ErrorMessage
                            name="Machine"
                            render={(message) => (
                              <span className="w-full text-xs font-semibold text-red-600">
                                {message}
                              </span>
                            )}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

FTTeixiduriaDetail.propTypes = {
  params: PropTypes.any.isRequired
}
