import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function ObservationsForm({ values }) {
  const formatDate = (dateString) => {
    const utcDate = new Date(dateString)
    const userTimeZoneOffset = new Date().getTimezoneOffset()
    const userLocalTime = new Date(
      utcDate.getTime() - userTimeZoneOffset * 60000
    )

    // Obtener los componentes de la fecha
    const day = ('0' + userLocalTime.getDate()).slice(-2)
    const month = ('0' + (userLocalTime.getMonth() + 1)).slice(-2)
    const year = userLocalTime.getFullYear()

    // Construir la cadena de fecha formateada
    const formattedLocalTime = `${day}-${month}-${year}`

    return formattedLocalTime
  }

  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Observació */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Observació:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          as="textarea"
          name="RequestArticle.Obseravtion"
          className="w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
        />
      </div>

      {/* NOTA IMPORTANT */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        NOTA IMPORTANT:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          as="textarea"
          name="RequestArticle.DestacableText"
          className="w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
        />
      </div>

      {/* Edició */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Edició:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.EditionNumber}
        </div>
      </div>

      {/* Data edició */}
      {/* <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Data edició:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.EditionDate ? formatDate(values?.EditionDate) : ''}
        </div>
      </div> */}
    </div>
  )
}

ObservationsForm.propTypes = {
  values: PropTypes.object
}
