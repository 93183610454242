import esquemaTeixiduria from 'assets/img/esquema-teixiduria.png'
import ToolTipIcon from 'components/icons/ToolTipIcon'
import { ModalContext } from 'contexts/ModalContext'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import FTTeixiduriaPrintLab from './FTTeixiduriaPrintLab'
import FTTeixiduriaPrintPos from './FTTeixiduriaPrintPos'
import ThreadTable from './threadTable'

export default function DescriptionForm({ fields }) {
  // Context
  const { handleModal } = useContext(ModalContext)

  // Fetch
  const [getApiCallGetSemiFinished, setApiCallSemiFinished] = useFetch()
  const [getApiCallGetThreads, setApiCallThreads] = useFetch()

  // Vars
  const isOpen = false
  const isOpenPos = false
  const commonInputStyles = 'p-0.5 text-sm border border-gray-300 rounded-sm'
  const checkStyles = 'w-6 h-6 border border-black print:flex'

  useEffect(async () => {
    // CRUD: GET (Semifinished):
    const apiCallGetSemiFinished = {
      url: `semifinished`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    // CRUD: GET (Threads):
    const apiCallGetThreads = {
      url: `thread`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    await Promise.all([
      setApiCallSemiFinished(apiCallGetSemiFinished),
      setApiCallThreads(apiCallGetThreads)
    ]).catch((e) => {
      console.log(e)
    })
  }, [])

  return (
    <>
      <div className="grid grid-cols-12 px-3 border-b border-blue-100 border-dashed divide-x divide-blue-100 print:px-1 grow divide-dashed">
        {/* Columna 1: */}
        <div className="grid items-center grid-cols-12 col-span-4 py-3 pr-3 auto-rows-min grow print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-0.5">
          {/* Semielaborat */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Semielaborat:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.SemiFinished}
              disabled
            />
          </div>

          {/* Tipus lligat */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Tipus lligat:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={
                getApiCallGetSemiFinished?.data?.find(
                  (option) => option.semi === fields.SemiFinished
                )?.ligado
              }
              disabled
            />
          </div>

          <hr className="col-span-12 my-1 border-2 border-blue-100 print:hidden" />

          {/* Table 'Ideal' | 'Tol. Màx.' */}
          <div className="grid grid-cols-12 col-span-12 gap-y-0.5">
            <div className="grid grid-cols-12 col-span-12">
              <div className="grid grid-cols-12 col-span-9 col-start-4 col-end-13 gap-x-2 print:gap-x-0">
                <div className="grid grid-cols-12 col-span-10 print:col-span-9">
                  <div className="flex flex-row items-center justify-center col-span-6 text-sm border-b border-r border-black font-ms-semi">
                    Ideal
                  </div>

                  <div className="flex flex-row items-center justify-center w-full col-span-6 col-end-13 text-sm border-b border-black font-ms-semi">
                    Tol. Màx.
                  </div>
                </div>
              </div>
            </div>

            {/* Campo 'Passades': */}
            <div className="grid grid-cols-12 col-span-12">
              <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
                Passades:
              </div>

              <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
                <div className="grid w-full grid-cols-12 col-span-10 print:col-span-9">
                  <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 print:pr-1 border-r border-black text-sm">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.Repetitions}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.RepetitionsTolerance}
                      disabled
                    />
                  </div>

                  <div className="flex flex-row items-center justify-center col-span-6 pl-1.5 print:pl-1">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.RepetitionsV2}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.RepetitionsToleranceV2}
                      disabled
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm print:text-xs print:col-span-3">
                  <div>u/cm</div>
                  <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
                </div>
              </div>
            </div>

            {/* Campo 'Columnes': */}
            <div className="grid grid-cols-12 col-span-12">
              <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
                Columnes:
              </div>

              <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
                <div className="grid w-full grid-cols-12 col-span-10 print:col-span-9">
                  <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 print:pr-1 border-r border-black text-sm">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.Columns}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.ColumnsTolerance}
                      disabled
                    />
                  </div>

                  <div className="flex flex-row items-center justify-center col-span-6 pl-1.5 print:pl-1">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.ColumnsV2}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.ColumnsToleranceV2}
                      disabled
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm print:text-xs print:col-span-3">
                  <div>u/cm</div>
                  <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
                </div>
              </div>
            </div>

            {/* Campo 'Gramatge': */}
            <div className="grid grid-cols-12 col-span-12">
              <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
                Gramatge:
              </div>

              <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
                <div className="grid w-full grid-cols-12 col-span-10 print:col-span-9">
                  <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 print:pr-1 border-r border-black text-sm">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.Weight}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.WeightTolerance}
                      disabled
                    />
                  </div>

                  <div className="flex flex-row items-center justify-center col-span-6 pl-1.5 print:pl-1">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.WeightV2}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.WeightToleranceV2}
                      disabled
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm print:text-xs print:col-span-3">
                  <div>g/m2</div>
                  <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
                </div>
              </div>
            </div>

            {/* Campo 'Ample': */}
            <div className="grid grid-cols-12 col-span-12">
              <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
                Ample:
              </div>

              <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
                <div className="grid w-full grid-cols-12 col-span-10 print:col-span-9">
                  <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 print:pr-1 border-r border-black text-sm">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.Width}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.WidthTolerance}
                      disabled
                    />
                  </div>

                  <div className="flex flex-row items-center justify-center col-span-6 pl-1.5 print:pl-1">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.WidthV2}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.WidthToleranceV2}
                      disabled
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm print:text-xs print:col-span-3">
                  <div>cm</div>
                  <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
                </div>
              </div>
            </div>

            {/* Campo 'Gruix': */}
            <div className="grid grid-cols-12 col-span-12">
              <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
                Gruix:
              </div>

              <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
                <div className="grid w-full grid-cols-12 col-span-10 print:col-span-9">
                  <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 print:pr-1 border-r border-black text-sm">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.Thickness}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.ThicknessTolerance}
                      disabled
                    />
                  </div>

                  <div className="flex flex-row items-center justify-center col-span-6 pl-1.5 print:pl-1">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.ThicknessV2}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.ThicknessToleranceV2}
                      disabled
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm print:text-xs print:col-span-3">
                  <div>mm</div>
                  <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
                </div>
              </div>
            </div>

            {/* Campo 'Rdt Nominal': */}
            <div className="grid grid-cols-12 col-span-12">
              <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
                Rdt Nominal:
              </div>

              <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
                <div className="grid w-full grid-cols-12 col-span-10 print:col-span-9">
                  <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 print:pr-1 border-r border-black text-sm">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.RDTNominal}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.RDTNominalTolerance}
                      disabled
                    />
                  </div>

                  <div className="flex flex-row items-center justify-center col-span-6 pl-1.5 print:pl-1">
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.RDTNominalV2}
                      disabled
                    />
                    &nbsp;&plusmn;&nbsp;
                    <input
                      type="text"
                      className={`${commonInputStyles} w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                      style={{ backgroundColor: 'white', color: 'black' }}
                      value={fields?.RDTNominalToleranceV2}
                      disabled
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm print:text-xs print:col-span-3">
                  <div>m/kg</div>
                  <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
                </div>
              </div>
            </div>

            {/* Campo 'Comentari': */}
            <div className="grid grid-cols-12 col-span-12 print:hidden">
              <div className="block col-span-12 text-sm text-grey-darker font-ms-semi">
                Comentari:
              </div>

              <input
                type="text"
                className={`${commonInputStyles} col-span-12 w-full leading-tight print:p-0 print:leading-none print:text-xs truncate overflow-hidden`}
                style={{ backgroundColor: 'white', color: 'black' }}
                value={fields?.LaboratoryComment}
                disabled
              />
            </div>
          </div>

          {/* Botones 'Accés Laboratori' y 'Posada en marxa' */}
          <div className="flex flex-row items-end justify-start col-span-12 mt-1 space-x-6 print:hidden">
            <button
              type="button"
              className="w-32 p-0 text-xs font-semibold text-red-600 align-top bg-blue-200 rounded h-7 hover:bg-blue-400 hover:text-white"
              onClick={() => {
                handleModal(<FTTeixiduriaPrintLab fields={fields} />, isOpen)
              }}
            >
              Accés Laboratori
            </button>

            <a target="_blank">
              <button
                type="button"
                className="w-32 p-0 text-xs font-semibold text-yellow-600 align-top bg-blue-200 rounded h-7 hover:bg-blue-400 hover:text-white"
                onClick={() => {
                  handleModal(
                    <FTTeixiduriaPrintPos
                      fieldsPos={fields}
                      isOpenPos={true}
                    />,
                    isOpenPos
                  )
                }}
              >
                Posada en marxa
              </button>
            </a>
          </div>
        </div>

        {/* Columna 2: */}
        <div className="grid items-center grid-cols-12 col-span-4 p-3 auto-rows-min grow print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-0.5">
          {/* Campo 'Lligat': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Lligat:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.TiedDescription}
              disabled
            />
          </div>

          {/* Diagrama: */}
          <div className="flex col-span-12">
            <p className="mr-1 text-right">
              1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />
              10
              <br />
              11
              <br />
              12
              <br />
            </p>

            <div
              className={`w-full px-2 py-1 pt-0 overflow-hidden leading-tight border border-gray-300 rounded-sm appearance-none resize-none bg-blue-50 focus:outline-none focus:shadow-outline faden`}
              style={{ whiteSpace: 'pre-line', lineHeight: '1.5rem' }}
            >
              {fields?.EncryptedCode}
            </div>

            <div
              onClick={() =>
                handleModal(<img src={esquemaTeixiduria} alt="llegenda" />)
              }
              className="cursor-pointer print:hidden"
              style={{ height: 'fit-content' }}
            >
              <ToolTipIcon width={20} height={20} className="ml-2 mr-1" />
            </div>
          </div>
        </div>

        {/* Columna 3: */}
        <div className="grid items-center grid-cols-12 col-span-4 py-3 pl-3 auto-rows-min grow print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-0.5">
          {/* Màquina */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Màquina:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-4/12`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.MachineCode}
              disabled
            />

            <input
              type="text"
              className={`${commonInputStyles} w-8/12`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.Machine}
              disabled
            />
          </div>

          {/* Plegador */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Plegador:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.FoldType}
              disabled
            />
          </div>

          <hr className="col-span-12 my-1 border-2 border-blue-100 print:hidden" />

          {/* GG - ø */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            GG - ø:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.GG}
              disabled
            />

            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.O}
              disabled
            />
          </div>

          {/* Nº Jocs */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Nº Jocs:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.NGame}
              disabled
            />
          </div>

          {/* Agulles */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Agulles:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.Needle}
              disabled
            />
          </div>

          {/* Rpm y Rpm (Mín.) */}
          <div className="flex items-center col-span-12 space-x-2 print:col-span-12">
            {/* Rpm */}
            <span className="text-sm font-ms-semi">Rpm:</span>
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.Rpm}
              disabled
            />

            {/* Rpm (Mín.) */}
            <span className="text-sm font-ms-semi">Mín.:</span>
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.RpmMin}
              disabled
            />

            <div className="flex-row items-center justify-end hidden print:flex">
              <span className={checkStyles} />
            </div>
          </div>

          {/* V. / Kg */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi print:hidden">
            V. / Kg:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:hidden print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.TurnsPerKg}
              disabled
            />
            <span>&plusmn;</span>
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.TurnsPerKgTolerance}
              disabled
            />
          </div>

          {/* V. / Peça (Reals) */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi print:hidden">
            V. / Peça (Reals):
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:hidden print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.TurnsPerPart}
              disabled
            />
          </div>

          {/* V. / Peça (Fitxa) */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi print:hidden">
            V. / Peça (Fitxa):
          </div>
          <div className="hidden col-span-12 text-sm print:flex print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            V. / Peça:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.TurnsPerPartByOperator}
              disabled
            />
            <span>&plusmn;</span>
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.TurnsPerPartByOperatorTolerance}
              disabled
            />
            <div className="flex-row items-center justify-end hidden print:flex">
              <span className={checkStyles} />
            </div>
          </div>

          {/* Kg peça */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Kg peça:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.KgPart}
              disabled
            />
            <span>&plusmn;</span>
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              value={fields?.KgPartTolerance}
              disabled
            />
          </div>

          {/* Pass. Seguretat */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Pass. Seguretat:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <div
              className={`${
                fields?.SecurityStep === 'true' || fields?.SecurityStep
                  ? 'w-4/12'
                  : 'w-full'
              } ${commonInputStyles}`}
            >
              {fields?.SecurityStep ? 'Sí' : 'No'}
            </div>

            {(fields?.SecurityStep === 'true' || fields?.SecurityStep) && (
              <input
                type="text"
                className={`${commonInputStyles} w-8/10`}
                style={{ backgroundColor: 'white', color: 'black' }}
                value={
                  fields?.SecurityStepDescription === undefined ||
                  !fields?.SecurityStepDescription
                    ? 'Segons cartell'
                    : fields?.SecurityStepDescription
                }
                disabled
              />
            )}
          </div>
        </div>
      </div>

      <div className="w-full p-2 border-t border-dashed print:flex print:flex-row print:justify-between print:items-center print:text-sm print:space-x-2">
        <ThreadTable
          threads={fields.TSWeavingThreads}
          numberGames={fields?.NGame ? Number(fields?.NGame) : 0}
        />
      </div>
    </>
  )
}

DescriptionForm.propTypes = {
  fields: PropTypes.object
}
