import corroEnrollatdorJMB2024 from 'assets/icons/corro-enrotllador-JMB-2024.png'
import corroEnrotllador from 'assets/icons/corro-enrotllador.png'
import pinyonsPlegador from 'assets/icons/pinyons-plegador.png'
import relacioPlegadorJMB2024 from 'assets/icons/relacio-plegador-JMB-2024.png'
import tensioPlegadorJMB2024 from 'assets/icons/tensio-plegador-JMB-2024.png'
import tensioPlegador from 'assets/icons/tensio-plegador.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function FolderForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  const commonInputStyles =
    'px-2 py-0.5 border rounded-sm text-gray-500 truncate overflow-hidden'

  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Campos si 'Machine' es 'PAL': A. Calandra */}
      {/* Campos si 'Machine' es 'MCH': A. Calandra, Posició */}
      {/* Campos si 'Machine' es 'SNT': A. Calandra, Pinyons Plegador */}
      {/* Campos si 'Machine' es 'MYR': A. Calandra, Pinyons Plegador, Tensió */}
      {/* Campos si 'Machine' es 'TRT': A. Calandra, Posició, Torque */}
      {/* Campos si 'Machine' es 'JMB': A. Calandra, ?, Pinyons Plegador, Tensió Plegador */}
      {/* Campos si 'Machine' es 'PLT': A. Calandra, Delta, Pinyons Plegador, Tensió del Plegador, Corró enrotllador */}

      {/* A. Calandra */}
      {/* Se muestra en todos los tipos de 'Machine', en 'JMB', 'PLT', 'PAL', 'TRT', 'MCH', 'MYR' y 'SNT': */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        A. Calandra:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          className={`${commonInputStyles} w-full ${
            valuesBefore?.Calandra !== valuesAfter?.Calandra
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          }`}
          value={
            side === 'left' ? valuesBefore?.Calandra : valuesAfter?.Calandra
          }
        />
      </div>

      {/* Delta: */}
      {/* Se muestra si 'Machine' es 'PLT': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'PLT')) && (
        <>
          <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Delta:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
            <Field
              type="text"
              className={`${commonInputStyles} w-full ${
                valuesBefore?.Delta !== valuesAfter?.Delta
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              }`}
              value={side === 'left' ? valuesBefore?.Delta : valuesAfter?.Delta}
            />
          </div>
        </>
      )}

      {/* Piston JUM y Pinyons Plegador (3, 4) */}
      {/* Se muestra si 'Machine' es 'JMB': */}
      {/* TODO */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
            Relació Plegador:
          </label>

          <div className="relative w-56 col-span-4 print:w-52">
            <img
              src={relacioPlegadorJMB2024}
              alt="Relació Plegador"
              className="w-full"
            />

            <div className="absolute bottom-0 left-0 flex items-center justify-around w-full h-full">
              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.PistonJUM_v2 !== valuesAfter?.PistonJUM_v2
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.PistonJUM_v2
                    : valuesAfter?.PistonJUM_v2
                }
              />

              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.PistonJUM2_v2 !== valuesAfter?.PistonJUM2_v2
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.PistonJUM2_v2
                    : valuesAfter?.PistonJUM2_v2
                }
              />
            </div>
          </div>
        </div>
      )}

      {/* Pinyons Plegador (1, 2) */}
      {/* Se muestra si 'Machine' es 'MYR', 'SNT', 'PLT', 'JMB': */}
      {((side === 'left' &&
        valuesBefore?.Machine?.slice(0, 3) !== 'TRT' &&
        valuesBefore?.Machine?.slice(0, 3) !== 'PAL' &&
        valuesBefore?.Machine?.slice(0, 3) !== 'MCH' &&
        valuesBefore?.Machine?.slice(0, 3) !== 'JMB') ||
        (side === 'right' &&
          valuesAfter?.Machine?.slice(0, 3) !== 'TRT' &&
          valuesAfter?.Machine?.slice(0, 3) !== 'PAL' &&
          valuesAfter?.Machine?.slice(0, 3) !== 'MCH' &&
          valuesAfter?.Machine?.slice(0, 3) !== 'JMB')) && (
        <div className="col-span-12">
          <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
            Pinyons Plegador (1, 2):
          </label>

          <div className="relative w-40 col-span-4 print:w-36">
            <img
              src={pinyonsPlegador}
              alt="pinyons plegador"
              className="w-full"
            />

            <div className="absolute bottom-0 right-2 h-full flex flex-col items-end justify-around w-full pr-1 py-1 print:pr-0.5 print:py-0.5">
              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.FolderingPiston1 !==
                  valuesAfter?.FolderingPiston1
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.FolderingPiston1
                    : valuesAfter?.FolderingPiston1
                }
              />

              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.FolderingPiston2 !==
                  valuesAfter?.FolderingPiston2
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.FolderingPiston2
                    : valuesAfter?.FolderingPiston2
                }
              />
            </div>
          </div>
        </div>
      )}

      {/* --------------------------------------------------- */}

      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'MCH') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'MCH') ||
        (side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'TRT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'TRT') ||
        (side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'MYR') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'MYR') ||
        (side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <hr className="h-px col-span-12 my-4 bg-gray-200 border-0"></hr>
      )}

      {/* --------------------------------------------------- */}

      {/* Tensió Plegador (1, 2) */}
      {/* Se muestra si 'Machine' es 'PLT': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'PLT')) && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-3 text-sm text-grey-darker font-ms-semi">
            Tensió Plegador (1, 2):
          </label>

          <div className="grid w-full grid-cols-2 gap-x-2">
            <div className="flex flex-col justify-center col-span-1 space-y-2">
              <div className="flex flex-row w-full space-x-1">
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P1:
                </label>

                <Field
                  type="text"
                  className={`${commonInputStyles} w-9/12 ${
                    valuesBefore?.P1 !== valuesAfter?.P1
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  }`}
                  value={side === 'left' ? valuesBefore?.P1 : valuesAfter?.P1}
                />
              </div>

              <div className="flex flex-row w-full space-x-1">
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P2:
                </label>

                <Field
                  type="text"
                  className={`${commonInputStyles} w-9/12 ${
                    valuesBefore?.P2 !== valuesAfter?.P2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  }`}
                  value={side === 'left' ? valuesBefore?.P2 : valuesAfter?.P2}
                />
              </div>

              <div className="flex flex-row w-full space-x-1">
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P3:
                </label>

                <Field
                  type="text"
                  className={`${commonInputStyles} w-9/12 ${
                    valuesBefore?.P3 !== valuesAfter?.P3
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  }`}
                  value={side === 'left' ? valuesBefore?.P3 : valuesAfter?.P3}
                />
              </div>
            </div>

            <div className="relative w-full col-span-1">
              <img
                src={tensioPlegador}
                alt="Tensió Plegador"
                className="mx-auto w-28 print:w-24"
              />

              <div className="absolute bottom-0 left-0 flex flex-col items-center justify-between w-full h-full">
                <Field
                  type="text"
                  className={`${commonInputStyles} w-20 ${
                    valuesBefore?.FolderTension1 !== valuesAfter?.FolderTension1
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  }`}
                  value={
                    side === 'left'
                      ? valuesBefore?.FolderTension1
                      : valuesAfter?.FolderTension1
                  }
                />

                <Field
                  type="text"
                  className={`${commonInputStyles} w-20 ${
                    valuesBefore?.FolderTension2 !== valuesAfter?.FolderTension2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  }`}
                  value={
                    side === 'left'
                      ? valuesBefore?.FolderTension2
                      : valuesAfter?.FolderTension2
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Tensió Plegador (3, 4) */}
      {/* Se muestra si 'Machine' es 'JMB': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center col-span-4 text-sm text-grey-darker font-ms-semi">
            Tensió Plegador:
          </label>

          <div className="relative w-56 col-span-4 print:w-52">
            <img
              src={tensioPlegadorJMB2024}
              alt="Tensió Plegador"
              className="w-full"
            />

            <div className="absolute bottom-0 left-0 flex items-center justify-around w-full h-full">
              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.FolderTension1_v2 !==
                  valuesAfter?.FolderTension1_v2
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.FolderTension1_v2
                    : valuesAfter?.FolderTension1_v2
                }
              />

              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.FolderTension2_v2 !==
                  valuesAfter?.FolderTension2_v2
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.FolderTension2_v2
                    : valuesAfter?.FolderTension2_v2
                }
              />
            </div>
          </div>
        </div>
      )}

      {/* Posició */}
      {/* Se muestra si 'Machine' es 'MCH': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'MCH') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'MCH')) && (
        <div className="flex flex-row w-full col-span-12">
          <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Posició:
          </label>

          <div className="flex flex-row items-center justify-center flex-grow">
            <Field
              type="text"
              className={`${commonInputStyles} w-full ${
                valuesBefore?.PositionMonarch !== valuesAfter?.PositionMonarch
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              }`}
              value={
                side === 'left'
                  ? valuesBefore?.PositionMonarch
                  : valuesAfter?.PositionMonarch
              }
            />
          </div>
        </div>
      )}

      {/* Posició y Torque */}
      {/* Se muestra si 'Machine' es 'TRT': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'TRT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'TRT')) && (
        <div className="col-span-12">
          <div className="flex flex-row w-full">
            <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Posició:
            </label>

            <div className="flex flex-row items-center justify-center flex-grow">
              <Field
                type="text"
                className={`${commonInputStyles} w-full ${
                  valuesBefore?.PositionTerrot1 !== valuesAfter?.PositionTerrot1
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.PositionTerrot1
                    : valuesAfter?.PositionTerrot1
                }
              />
            </div>
          </div>

          <div className="flex flex-row w-full pt-2">
            <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Torque:
            </label>

            <div className="flex flex-row items-center justify-center flex-grow">
              <Field
                type="text"
                className={`${commonInputStyles} w-full ${
                  valuesBefore?.PositionTerrot2 !== valuesAfter?.PositionTerrot2
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.PositionTerrot2
                    : valuesAfter?.PositionTerrot2
                }
              />
            </div>
          </div>
        </div>
      )}

      {/* Tensió */}
      {/* Se muestra si 'Machine' es 'MYR': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'MYR') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'MYR')) && (
        <div className="flex flex-row w-full col-span-12">
          <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Tensió:
          </label>

          <div className="flex flex-row items-center justify-center flex-grow">
            <Field
              type="text"
              className={`${commonInputStyles} w-full ${
                valuesBefore?.TensionMayer !== valuesAfter?.TensionMayer
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              }`}
              value={
                side === 'left'
                  ? valuesBefore?.TensionMayer
                  : valuesAfter?.TensionMayer
              }
            />
          </div>
        </div>
      )}

      {/* --------------------------------------------------- */}

      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <hr className="h-px col-span-12 my-4 bg-gray-200 border-0"></hr>
      )}

      {/* --------------------------------------------------- */}

      {/* Corró enrotllador */}
      {/* Se muestra si 'Machine' es 'PLT': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'PLT') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'PLT')) && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center col-span-4 pb-1 text-sm text-grey-darker font-ms-semi">
            Corró enrotllador:
          </label>

          <div className="relative w-full col-span-4">
            <img
              src={corroEnrotllador}
              alt="Tensió Plegador"
              className="mx-auto md:w-28 xl:w-32 print:w-28"
            />

            <div className="absolute bottom-0 flex flex-col items-start justify-around w-auto h-full md:left-0 lg:left-8 xl:left-20 2xl:left-32">
              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.RollingRoller1 !== valuesAfter?.RollingRoller1
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller1
                    : valuesAfter?.RollingRoller1
                }
              />

              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.RollingRoller3 !== valuesAfter?.RollingRoller3
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller3
                    : valuesAfter?.RollingRoller3
                }
              />
            </div>

            <div className="absolute bottom-0 flex flex-col items-end justify-between w-auto h-full md:right-0 lg:right-8 xl:right-20 2xl:right-32">
              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.RollingRoller2 !== valuesAfter?.RollingRoller2
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller2
                    : valuesAfter?.RollingRoller2
                }
              />

              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.RollingRoller4 !== valuesAfter?.RollingRoller4
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller4
                    : valuesAfter?.RollingRoller4
                }
              />
            </div>
          </div>
        </div>
      )}

      {/* Tensió Plegador (3, 4) */}
      {/* Se muestra si 'Machine' es 'JMB': */}
      {((side === 'left' && valuesBefore?.Machine?.slice(0, 3) === 'JMB') ||
        (side === 'right' && valuesAfter?.Machine?.slice(0, 3) === 'JMB')) && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-1 text-sm text-grey-darker font-ms-semi">
            Corró Enrotllador:
          </label>

          <div className="flex flex-row space-x-4">
            <div className="flex items-start justify-start pt-6">
              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.RollingRoller1_v2 !==
                  valuesAfter?.RollingRoller1_v2
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller1_v2
                    : valuesAfter?.RollingRoller1_v2
                }
              />
            </div>

            <img
              src={corroEnrollatdorJMB2024}
              alt="Corró Enrotllador"
              className="w-16 print:w-14"
            />

            <div className="flex items-end justify-end pb-6">
              <Field
                type="text"
                className={`${commonInputStyles} w-20 ${
                  valuesBefore?.RollingRoller2_v2 !==
                  valuesAfter?.RollingRoller2_v2
                    ? side === 'left'
                      ? 'bg-red-100 border-red-300'
                      : isAnswered
                      ? 'bg-emerald-100 border-emerald-300'
                      : 'bg-amber-100 border-amber-300'
                    : 'border-gray-300 bg-gray-100'
                }`}
                value={
                  side === 'left'
                    ? valuesBefore?.RollingRoller2_v2
                    : valuesAfter?.RollingRoller2_v2
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

FolderForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
