import ThreadTable from './threadTable'

export default function DescriptionForm() {
  const commonInputStyles = 'p-0.5 text-sm border border-gray-300 rounded-sm'
  const checkStyles = 'w-6 h-6 border border-black print:flex'

  return (
    <>
      <div className="grid grid-cols-12 px-3 border-b border-blue-100 border-dashed divide-x divide-blue-100 print:px-1 grow divide-dashed">
        {/* Columna 1: */}
        <div className="grid items-center grid-cols-12 col-span-4 py-3 pr-3 auto-rows-min grow print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1">
          {/* Semielaborat */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Semielaborat:
          </div>
          <div className="flex items-center col-span-12 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          {/* Tipus lligat */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Tipus lligat:
          </div>
          <div className="flex items-center col-span-12 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          <hr className="col-span-12 my-1 border-2 border-blue-100 print:hidden" />

          {/* Campo 'Passades': */}
          <div className="grid grid-cols-12 col-span-12">
            <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
              Passades:
            </div>

            <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
              <div className="flex flex-row items-center justify-center w-full col-span-10 text-sm print:col-span-9">
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-between col-span-2 space-x-1 print:text-xs print:col-span-3">
                <div>u/cm</div>
                <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
              </div>
            </div>
          </div>

          {/* Campo 'Columnes': */}
          <div className="grid grid-cols-12 col-span-12">
            <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
              Columnes:
            </div>

            <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
              <div className="flex flex-row items-center justify-center w-full col-span-10 text-sm print:col-span-9">
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-between col-span-2 space-x-1 print:text-xs print:col-span-3">
                <div>u/cm</div>
                <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
              </div>
            </div>
          </div>

          {/* Campo 'Gramatge': */}
          <div className="grid grid-cols-12 col-span-12">
            <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
              Gramatge:
            </div>

            <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
              <div className="flex flex-row items-center justify-center w-full col-span-10 text-sm print:col-span-9">
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-between col-span-2 space-x-1 print:text-xs print:col-span-3">
                <div>g/m2</div>
                <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
              </div>
            </div>
          </div>

          {/* Campo 'Ample': */}
          <div className="grid grid-cols-12 col-span-12">
            <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
              Ample:
            </div>

            <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
              <div className="flex flex-row items-center justify-center w-full col-span-10 text-sm print:col-span-9">
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-between col-span-2 space-x-1 print:text-xs print:col-span-3">
                <div>cm</div>
                <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
              </div>
            </div>
          </div>

          {/* Campo 'Gruix': */}
          <div className="grid grid-cols-12 col-span-12">
            <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
              Gruix:
            </div>

            <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
              <div className="flex flex-row items-center justify-center w-full col-span-10 text-sm print:col-span-9">
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-between col-span-2 space-x-1 print:text-xs print:col-span-3">
                <div>mm</div>
                <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
              </div>
            </div>
          </div>

          {/* Campo 'Rdt Nominal': */}
          <div className="grid grid-cols-12 col-span-12">
            <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
              Rdt Nominal:
            </div>

            <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end print:gap-x-0">
              <div className="flex flex-row items-center justify-center w-full col-span-10 text-sm print:col-span-9">
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
                &nbsp;&plusmn;&nbsp;
                <input
                  type="text"
                  className={`${commonInputStyles} w-full truncate overflow-hidden`}
                  style={{ backgroundColor: 'white', color: 'black' }}
                  disabled
                />
              </div>

              <div className="flex flex-row items-center justify-between col-span-2 space-x-1 print:text-xs print:col-span-3">
                <div>m/kg</div>
                <div className="hidden w-6 h-6 border border-black print:inline-block"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Columna 2: */}
        <div className="grid items-center grid-cols-12 col-span-4 p-3 auto-rows-min grow print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1">
          {/* Campo 'Lligat': */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Lligat:
          </div>
          <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          {/* Diagrama: */}
          <div className="flex col-span-12">
            <p className="mr-1 text-right">
              1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />
              10
              <br />
              11
              <br />
              12
              <br />
            </p>

            <div className="flex items-center justify-center w-full px-2 py-1 pt-0 overflow-hidden leading-tight border border-gray-300 rounded-sm appearance-none resize-none bg-blue-50">
              <span className="text-2xl font-bold text-center align-middle opacity-40 lg:text-5xl xl:text-6xl 2xl:text-7xl font-ms-regular print:text-6xl">
                PROTO
              </span>
            </div>
          </div>
        </div>

        {/* Columna 3: */}
        <div className="grid items-center grid-cols-12 col-span-4 py-3 pl-3 auto-rows-min grow print:p-1.5 md:gap-y-1 lg:gap-y-3 print:gap-y-1">
          {/* Màquina */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Màquina:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-4/12`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />

            <input
              type="text"
              className={`${commonInputStyles} w-8/12`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          {/* Plegador */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Plegador:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          <hr className="col-span-12 my-1 border-2 border-blue-100 print:hidden" />

          {/* GG - ø */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            GG - ø:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />

            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          {/* Nº Jocs */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Nº Jocs:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          {/* Agulles */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Agulles:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          {/* Rpm y Rpm (Mín.) */}
          <div className="flex items-center col-span-12 space-x-2 print:col-span-12">
            {/* Rpm */}
            <span className="text-sm font-ms-semi">Rpm:</span>
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />

            {/* Rpm (Mín.) */}
            <span className="text-sm font-ms-semi">Mín.:</span>
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />

            <div className="flex-row items-center justify-end hidden print:flex">
              <span className={checkStyles} />
            </div>
          </div>

          {/* V. / Kg */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi print:hidden">
            V. / Kg:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:hidden print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
            <span>&plusmn;</span>
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          {/* V. / Peça (Reals) */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi print:hidden">
            V. / Peça (Reals):
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:hidden print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          {/* V. / Peça (Fitxa) */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi print:hidden">
            V. / Peça (Fitxa):
          </div>
          <div className="hidden col-span-12 text-sm print:flex print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            V. / Peça:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
            <span>&plusmn;</span>
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
            <div className="flex-row items-center justify-end hidden print:flex">
              <span className={checkStyles} />
            </div>
          </div>

          {/* Kg peça */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Kg peça:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
            <span>&plusmn;</span>
            <input
              type="text"
              className={`${commonInputStyles} w-full`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>

          {/* Pass. Seguretat */}
          <div className="col-span-12 text-sm print:col-span-3 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Pass. Seguretat:
          </div>
          <div className="flex items-center col-span-12 space-x-2 print:col-span-9 lg:col-span-8 xl:col-span-9">
            <input
              type="text"
              className={`${commonInputStyles} w-4/12`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />

            <input
              type="text"
              className={`${commonInputStyles} flex-grow`}
              style={{ backgroundColor: 'white', color: 'black' }}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="w-full p-2 border-t border-dashed print:flex print:flex-row print:justify-between print:items-center print:text-sm print:space-x-2">
        <ThreadTable />
      </div>
    </>
  )
}

DescriptionForm.propTypes = {}
